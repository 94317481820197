export function slugify(str) {
  if (!str) return str
  const umlautsMap = {
    'ä': 'ae',
    'ö': 'oe',
    'ü': 'ue',
    'ß': 'ss',
    'Ä': 'ae',
    'Ö': 'oe',
    'Ü': 'ue'
  };

  // Replace German Umlauts with their non-Umlaut equivalents
  str = str.trim()
  str = str.replace(/[äöüßÄÖÜ]/g, match => umlautsMap[match]);

  // Perform regular slugification
  return str
    .toString()
    .toLowerCase()
    .trim()
    .replace(/[\s\W-]+/g, '-');
}

export var ExhibitorMixin = {
  methods: {
    exhibitorUrl: function(exhibitor) {
      let sn = this.$route.params['shortName']
      let url = `exhibitors/${exhibitor.id}-${slugify(exhibitor.company_name)}`
      if (exhibitor.lead?.id)
        url += `?lead_id=${exhibitor.lead.id}`
      return sn ? `/${sn}/${url}` : `/${url}`
    }
  }
}

function speakerNameSlug(speaker) { 
  let fn = slugify(speaker.first_name)
  let ln = slugify(speaker.last_name)
  return `${fn}-${ln}`
}

export var SpeakerMixin = {
  methods: {
    speakerUrl: function(speaker) { 
      let sn = this.$route.params['shortName']
      let url = `speakers/${speaker.id}-${speakerNameSlug(speaker)}`
      return sn ? `/${sn}/${url}` : `/${url}`
    },

    speakerFullName: function(speaker) {
      return [speaker.title, speaker.first_name, speaker.last_name].join(' ').trim()
    }
  }
}