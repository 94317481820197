<template>
  <div>
    <event-navbar title="Community Contact" />
    <div class="container-fluid">
      <contact-detail :contactId="$route.params.contactId" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactDetail from '@/components/speaker/contact-detail.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'ContactDetailView',
  components: {
    ContactDetail, EventNavbar
  },
}
</script>
