import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/axios'
import './plugins/bootstrap-vue'
import './plugins/mattermost-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false
Vue.prototype.$backendUrl = process.env.VUE_APP_ROOT_URL

import {FavoriteStorage} from '@/components/speaker/storage.js'
Vue.prototype.$storage = new FavoriteStorage(Vue.prototype.$api, Vue.prototype.$mattermost)
Vue.prototype.$storage.fetchUser()

import TalkList from '@/components/speaker/talk-list.vue';
import TalkDetail from '@/components/speaker/talk-detail.vue';
import TalkDetailPage from '@/components/speaker/talk-detail-page.vue';
import SpeakerList from '@/components/speaker/speaker-list.vue';
import SpeakerDetail from '@/components/speaker/speaker-detail.vue';
import SpeakerThumb from '@/components/speaker/speaker-thumb.vue';
import SpeakerCard from '@/components/speaker/speaker-card.vue';
import ChannelActivity from '@/components/channel/channel-activity.vue';

Vue.component('talk-list', TalkList)
Vue.component('talk-detail', TalkDetail)
Vue.component('talk-detail-page', TalkDetailPage)
Vue.component('speaker-list', SpeakerList)
Vue.component('speaker-detail', SpeakerDetail)
Vue.component('speaker-thumb', SpeakerThumb)
Vue.component('speaker-card', SpeakerCard)
Vue.component('channel-activity', ChannelActivity)

import ProductIndex from '@/components/exhibitor/product-index.vue';
import ExhibitorList from '@/components/exhibitor/exhibitor-list.vue';
Vue.component('product-index', ProductIndex)
Vue.component('exhibitor-list', ExhibitorList)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
