<script>
import UserNavItem from "./UserNavItem.vue"
export default {
    components: {UserNavItem},
    props: {
        title: {type: String, required: true},
        faIcon: {type: String, default: 'fa fa-chevron-left'},
        userNav: {type: Boolean, default: true}
    },
}
</script>

<template>
    <nav class="nav navbar">
      <div class="nav-item">
        <button class="btn btn-outline" @click.prevent="$router.back()">
          <i :class="faIcon"></i>
        </button>
      </div>
      <div class="navbar-brand ml-auto">
        <b>{{ $route.params.shortName?.toUpperCase() }} {{ title }}</b>
      </div>
      <user-nav-item v-if="userNav" @new-unread-messages="$emit('new-unread-messages', $event)" />
      <div v-else class="nav-item">
        <router-link class="btn btn-outline" to="/">
          <i class="fa fa-home"></i>
        </router-link>
      </div>

    </nav>
</template>
