// @ is an alias to /src

export default {
  name: 'ListViewStorageMixin',

  data() {
    return {
      header_content: '',
      footer_content: '',
    }
  },

  methods: {
    storageKey(key) {
      return `zpcard-info-content-${this.$route.params.shortName}-${key}`
    },
    fetchBannerContent(key) {
      let me = this
      if (navigator.onLine) {
        this.$api.get(`zpcard/${this.$route.params.shortName}/${key}/`).then((response) => {
          me.header_content = response.data.header
          me.footer_content = response.data.footer
          localStorage.setItem(this.storageKey, [me.header_content, me.footer_content])
        }).catch(() => {
          console.log('no talks page header/footer def.')
        })
      } else {
          me.header_content = localStorage.getItem(this.storageKey)[0]
          me.footer_content = localStorage.getItem(this.storageKey)[1]
      }
    }
  },
}
