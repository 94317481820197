<template>
  <div>
    <nav class="nav navbar">
      <div class="d-flex flex-row">
        <div class="nav-item">
          <router-link to="/" class="nav-link"><i class="fas fa-home"></i></router-link>
        </div>
      </div>
      <div class="navbar-brand"><b>{{ $route.params.shortName.toUpperCase() }} - Event Home</b></div>
      <user-nav-item />
    </nav>
    <v-runtime-template :template="event_content"></v-runtime-template>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
import VRuntimeTemplate from "v-runtime-template";
import UserNavItem from '@/components/UserNavItem.vue';

export default {
  name: 'EventHomeView',
  components: {
    VRuntimeTemplate,
    UserNavItem
  },
  data() {
    return {
      event_content: '',
    }
  },

  created() {
      this.$storage.fetchUser()  // to update the favorite storage
      let me = this
      if (navigator.onLine) {
        this.$api.get(`zpcard/${this.$route.params.shortName}/`).then((response) => {
          me.event_content = response.data.html
          localStorage.setItem('zpcard-event-home', me.event_content)
        })
      } else {
          me.event_content = localStorage.getItem('zpcard-event-home')
      }
  },

}
</script>
