export default {
  data() {
    return {
      favoritesByTitle: {
        'Vortrags Favoriten': 'talk',
        'Speaker Favoriten': 'speaker',
        'Aussteller Favoriten': 'exhibitor',
        'Sponsor Favoriten': 'sponsor',
      },
    }
  },

  computed: {
    favoritesByKey() {
      return Object.fromEntries(
        Object.entries(this.favoritesByTitle).map(([key, value]) => [value, key]))
    }
  }
}