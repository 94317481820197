<template>
  <div>
    <event-navbar title="Speaker" />
    <div class="container-fluid">
      <speaker-detail :speakerId="$route.params.speakerId" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SpeakerDetail from '@/components/speaker/speaker-detail.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'SpeakerDetailView',
  components: {
    SpeakerDetail, EventNavbar
  },
}
</script>
