<template>
  <div>
    <v-runtime-template :template="header_content"></v-runtime-template>
    <event-navbar title="Speaker" />
    <div class="container-fluid">
      <speaker-list :event-short-name="$route.params.shortName"></speaker-list>
    </div>
    <v-runtime-template :template="footer_content"></v-runtime-template>
  </div>
</template>

<script>
// @ is an alias to /src
import ListViewStorageMixin from './ListViewStorageMixin.js'
import VRuntimeTemplate from "v-runtime-template";
import SpeakerList from '@/components/speaker/speaker-list.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'SpeakerListView',
  mixins: [ListViewStorageMixin],
  components: {
    SpeakerList, EventNavbar, VRuntimeTemplate
  },
  created() {
    this.fetchBannerContent('speakers')
  },
}
</script>
