<template>
  <div>
    <event-navbar title="Session" />
    <div class="container-fluid">
      <talk-detail-page :talkId="$route.params.talkId" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TalkDetailPage from '@/components/speaker/talk-detail-page.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'SpeakerDetailView',
  components: {
    TalkDetailPage, EventNavbar
  },

  methods: {
  }
}
</script>
