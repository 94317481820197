<template>
  <div>
    <event-navbar title="Aussteller" />
    <v-runtime-template :template="header_content"></v-runtime-template>
    <div class="container-fluid">
      <exhibitor-list :event-short-name="$route.params.shortName"></exhibitor-list>
    </div>
    <v-runtime-template :template="footer_content"></v-runtime-template>
  </div>
</template>

<script>
// @ is an alias to /src
import ListViewStorageMixin from './ListViewStorageMixin.js'
import VRuntimeTemplate from "v-runtime-template";
import ExhibitorList from '@/components/exhibitor/exhibitor-list.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'ExhibitorListView',
  mixins: [ListViewStorageMixin],
  components: {
    ExhibitorList, EventNavbar, VRuntimeTemplate
  },
  created() {
    this.fetchBannerContent('exhibitors')
  },
}
</script>
