import {marked} from 'marked'
import {markedEmoji} from "marked-emoji"
import {mdEmojiOptions} from "../lib/md-emojis"

marked.use(markedEmoji(mdEmojiOptions))

export default {
  props: {
    channelId: {type: String, required: true},
  },

  data() {
    return {
      postsPerPage: 35,
      reverseOrder: true,
      pinnedOnly: false,
      orderedPostIds: [],
      posts: {},
      postImages: {},
      postReactions: {},
      currentPage: 0,
      prevPostId: '',
      users: {},
      avatars: {},
      postInput: '',
      postInputRootId: '',
      postEditId: '',
      connected: false,
      minRows: 1,
      maxRows: 4,
      emojis: mdEmojiOptions.emojis,
      loggedIn: false,
      error: '',
      subcribeTries: 0,
      extraRows: 0,
    }
  },

  computed: {
    currentRows() {
      // Calculate the number of rows based on the textarea content
      const lines = this.postInput.split('\n').length + this.extraRows;
      this.extraRows = 0
      return Math.min(this.maxRows, Math.max(this.minRows, lines));
    },
    isOffline() {
      return !navigator.onLine
    },
  },

  created() {
    if (this.$storage.getMattermostToken() && navigator.onLine) {
        this.fetchChannelPosts()
        this.subscribeWebSocket()
        this.markChannelAsRead()
    }
  },

  beforeDestroy() {
    this.unSubscribeWebSocket()
  },

  methods: {
    markChannelAsRead() {
      this.$storage.markChannelAsRead(this.channelId)
    },

    onPostClick(postId, event) {
      if (event.target.tagName.toLowerCase() === 'a') {
        return; // Link wurde angeklickt, Menü nicht anzeigen
      }
      this.postInputRootId = ''
      this.postEditId = ''
      let value = !this.posts[postId].showMenu
      this.$set(this.posts[postId], 'showMenu', value)
      if (value) {
        for (const [pid, post] of Object.entries(this.posts)) {
          if (pid == postId) continue
          if (!post.showMenu) continue
          this.$set(this.posts[pid], 'showMenu', false)
        }
      }
    },

    convert2Html(markdown) {
      return marked(markdown)
    },

    getFormatedDate(timestamp) {
      let date = new Date(parseInt(timestamp))
      return date.toISOString().slice(0,10) + ' ' + date.toTimeString().slice(0,5)
    },

    scrollDown() {
      let content = document.querySelector('.c-chat-content');
      if (content) {
        content.scrollTop = content.scrollHeight;
      } else {
        try {
          window.scrollTo(0, document.body.scrollHeight);
        } catch (error) { console.error(error) }
      }
    },

    userFullName(postId) {
      let user = this.users[this.posts[postId].user_id]
      return `${user.first_name} ${user.last_name}`.trim() || user.username
    },

    getUserIds(posts) {
      let userIds = new Set()
      for (const post of posts) {
        userIds.add(post.user_id)
      }
      return Array.from(userIds)
    },

    fetchAndSetAvatars(users, userIds) {
      let me = this
      let params = {limit: userIds.length, mmid: userIds}
      me.$api.get('/community-users', {params: params}).then(response => {
        for (const user of response.data.results) {
          me.$set(me.avatars, user.mmid, user.profile_image)
        }

        // fetch the rest of avatars from mattermost
        for (const user of users) {
          if (!me.avatars[user.id]) {
            this.$mattermost.get(`/users/${user.id}/image`, {responseType: 'arraybuffer'}).then(response => {
              const blob = new Blob([response.data], { type: 'image/png' });
              // Create a URL for the Blob
              me.$set(me.avatars, user.id,  URL.createObjectURL(blob))
            })
          }
        }
      })

    },

    fetchPostFiles() {
      let me = this
      for (const post of Object.values(this.posts)) {
        this.$mattermost.get(`/posts/${post.id}/files/info`).then(response => {
          let images = []
          for (const info of response.data) {
            if (info.mime_type && info.mime_type.startsWith('image')) {
              this.$mattermost.get(`/files/${info.id}/preview`, {responseType: 'arraybuffer'}).then(response => {
                const blob = new Blob([response.data], { type: info.mime_type })
                images.push({
                  src: URL.createObjectURL(blob),
                  name: info.name
                })
                me.$set(me.postImages, post.id, images)
              })
            }
          }
        })
      }
    },

    addPostReaction(postId, emojiName, userId) {
      if (postId in this.postReactions) {
        if (emojiName in this.postReactions[postId]) {
          let users = new Set([...this.postReactions[postId][emojiName], userId])
          this.$set(this.postReactions[postId], emojiName, users)
        } else {
          this.$set(this.postReactions[postId], emojiName, new Set([userId]))
        }
      } else {
        let info = {}
        info[emojiName] = new Set([userId])
        this.$set(this.postReactions, postId, info)
      }
    },

    removePostReaction(postId, emojiName, userId) {
      let set = this.postReactions[postId][emojiName]
      set.delete(userId)
      this.$set(this.postReactions[postId], emojiName, new Set([...set]))
    },

    fetchPostReactions() {
      let me = this
      for (const post of Object.values(this.posts)) {
        this.$mattermost.get(`/posts/${post.id}/reactions`).then(response => {
          if (response.data) {
            for (const reaction of response.data) {
              me.addPostReaction(post.id, reaction.emoji_name, reaction.user_id)
            }
          }
        })
      }
    },

    updatePost(post) {
      this.$set(this.posts[post.id], 'message', post.message)
    },

    removePost(postId) {
      delete this.postImages[postId]
      delete this.postReactions[postId]
      delete this.posts[postId]
      let idx = this.orderedPostIds.indexOf(postId)
      this.orderedPostIds.splice(idx, 1)
    },

    fetchOlderPosts() {
      this.currentPage++
      this.fetchChannelPosts(false)
    },

    subscribeChannel() {
      let me = this
      let url = `/channels/${this.channelId}/members`
      let payload = {user_id: this.$storage.get('mm_user_id', null)}
      me.subcribeTries += 1 
      me.$mattermost.post(url, payload).then(response => {
        if (me.subcribeTries < 2) me.fetchChannelPosts()
      })
    },

    fetchChannelPosts(scrollDown=true) {
      let me = this
      let params = {
        page: this.currentPage,
        per_page: this.postsPerPage,
      }
      let url = this.pinnedOnly ? `/channels/${this.channelId}/pinned` : `/channels/${this.channelId}/posts`
      this.$mattermost.get(url, {params: params}).then(response => {
          me.error = ''
          me.orderedPostIds.unshift(...response.data.order.reverse())
          me.posts = { ...me.posts, ...response.data.posts }

          me.prevPostId = response.data.prev_post_id
          if (me.orderedPostIds.length) {
            let userIds = me.getUserIds(Object.values(me.posts))
            this.$mattermost.post('/users/ids', userIds).then(response => {
              me.users = {}
              for (const user of response.data) {
                me.users[user.id] = user
              }
              me.fetchAndSetAvatars(response.data, userIds)
              me.fetchPostFiles()
              me.fetchPostReactions()
              if (scrollDown && me.reverseOrder)
                setTimeout(function() {me.scrollDown()}, 500);
            })
          }
      }, error_response => {
        console.error(error_response)
        if (error_response.response.status == 403) me.subscribeChannel()
        me.error = 'cannot fetch posts, check channel access and ID!'
      })
    },

    postMessage() {
      console.log('channel-mixin.postMessage()')
      if (this.postEditId) {
        this.putPost(this.postEditId)
      } else {
        this.createPost()
      }
    },

    createPost() {
        let payload = {
          channel_id: this.channelId,
          message: this.postInput,
        }
        if (this.postInputRootId)
          payload.root_id = this.postInputRootId

        let me = this
        return this.$mattermost.post(`/posts`, payload).then((response) => {
            me.postInput = ''
            me.postInputRootId = ''
            if (me.reverseOrder) setTimeout(function() {me.scrollDown()}, 300);
            return response
        })
    },

    putPost(postId) {
        let payload = {
          id: postId,
          message: this.postInput,
        }
        let me = this
        return this.$mattermost.put(`/posts/${postId}`, payload).then((response) => {
            me.postInput = ''
            me.postEditId = ''
            me.postInputRootId = ''
            return response
        })
    },

    editPost(postId) {
      this.posts[postId].showMenu = false
      this.postEditId = postId
      this.postInput = this.posts[postId].message
      this.$refs.postInput.focus()
    },

    deletePost(postId) {
        this.$mattermost.delete(`/posts/${postId}`)
    },

    fetchOrCreateDirectChannel(userId, otherUserId) {
      return this.$storage.fetchContactsForUser(userId).then(response => {
        if (response.data.contactIds.indexOf(otherUserId) < 0) {
          // create direct channel
          let payload = [userId, otherUserId]
          return this.$mattermost.post('/channels/direct', payload).then(response => {
            return response.data
          })
        } else {
            let cs = response.data.directChannels.filter(channel => channel.name.includes(otherUserId))
            return cs[0]
        }
      })
    },

    formatReply(username, oldMessage) {
      function getLine(str, num=0) {
        let lines = str.split('\n')
        if (lines.length > num) {
          return lines[num].substring(0, 119).trim()
        }
        return ''
      }
      function stripReplyLines(str) {
        let lines = str.split('\n');

        // Filter out lines that start with ">"
        let filteredLines = lines.filter(line => !line.trim().startsWith('>'))
        return filteredLines.join('\n').trim()
      }

      let msg = stripReplyLines(oldMessage)
      msg = `> ${username}: ${getLine(msg)}`
      let secondLn = getLine(oldMessage, 1)
      if (secondLn) {
        msg += ` ${secondLn}`
        this.extraRows = 1
      }
      let thirdLn = getLine(oldMessage, 2)
      if (thirdLn) {
        msg += ` ${thirdLn}`
        this.extraRows = 2
      }
      msg += '\n\n'
      return msg
    },

    replyPrivateToPost(postId) {
      let userId = this.$storage.get('mm_user_id')
      let otherUserId = this.posts[postId].user_id
      this.fetchOrCreateDirectChannel(userId, otherUserId).then(response => {
        let channelId = response.id
        let url = `/community/${otherUserId}/${channelId}?reply_to_post_id=${postId}`
        this.$router.push(url)
      })
    },

    replyToPost(postId) {
      this.posts[postId].showMenu = false
      this.postInputRootId = this.posts[postId].root_id || postId
      let username = this.users[this.posts[postId].user_id].username
      this.postInput = this.formatReply(username, this.posts[postId].message)
      this.$refs.postInput.focus()
    },

    reactToPost(postId, emojiName) {
        this.posts[postId].showMenu = false
        let userId = this.$storage.get('mm_user_id')
        if (postId in this.postReactions && emojiName in this.postReactions[postId] &&
            this.postReactions[postId][emojiName].has(userId)) {
          // delete reaction
          this.$mattermost.delete(`/users/${userId}/posts/${postId}/reactions/${emojiName}`)
        } else {
          // add reaction
          let payload = {
            'user_id': userId,
            'post_id': postId,
            'emoji_name': emojiName,
          }
          this.$mattermost.post(`/reactions`, payload)
        }
    },

    subscribeWebSocket() {
      if (this.$storage.websocket) {
        console.log('subscribe mattermost websocket.')
        this.$storage.websocket.addEventListener('message', this.onWebsocketMessage)
      }
    },

    unSubscribeWebSocket() {
      console.log('unsubscribe mattermost websocket.')
      if (this.$storage.websocket) {
        console.log('unsubscribe mattermost websocket.')
        this.$storage.websocket.removeEventListener('message', this.onWebsocketMessage)
      }
    },

    onWebsocketMessage(event) {
      let me = this
      const message = JSON.parse(event.data);
      if (message.event === 'posted') {
        let post = JSON.parse(message.data.post)
        if (post.channel_id === me.channelId) {
          console.log('New post received:', message.data);
          me.orderedPostIds.push(post.id)
          me.$set(me.posts, post.id, post)
          if (me.reverseOrder) setTimeout(function() {me.scrollDown()}, 300);
        }
      } else if (message.event === 'reaction_added') {
        let reaction = JSON.parse(message.data.reaction)
        me.addPostReaction(reaction.post_id, reaction.emoji_name, reaction.user_id)
      } else if (message.event === 'reaction_removed') {
        let reaction = JSON.parse(message.data.reaction)
        me.removePostReaction(reaction.post_id, reaction.emoji_name, reaction.user_id)
      } else if (message.event === 'post_edited') {
        let post = JSON.parse(message.data.post)
        me.posts[post.id] = post
        if (me.pinnedOnly && post.is_pinned)
          me.orderedPostIds.unshift(post.id)
        me.updatePost(post)
      } else if (message.event === 'post_deleted') {
        let post = JSON.parse(message.data.post)
        me.removePost(post.id)
      }
    }
  }
}