import { render, staticRenderFns } from "./exhibitor-navbar.vue?vue&type=template&id=ebd5059a&scoped=true"
import script from "./exhibitor-navbar.vue?vue&type=script&lang=js"
export * from "./exhibitor-navbar.vue?vue&type=script&lang=js"
import style0 from "./exhibitor-navbar.vue?vue&type=style&index=0&id=ebd5059a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../zpcard/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd5059a",
  null
  
)

export default component.exports