<template>
  <div id="app" class="h-100">
    <router-view/>
    <toast v-if="message" :message="message" @closed="message=''"/>
  </div>
</template>

<script>
import Toast from "@/components/lib/toast.vue"

export default {
  name: 'ZpApp',
  components: {Toast},
  data() {
    return {
      message: '',
    }
  },
  mounted() {
    if ('Notification' in window && 'serviceWorker' in navigator && this.$storage.getToken()) {
      this.$storage.askForPushNotificationPermission()

      setTimeout(() => {
        // delayed setup of event listener
        console.log('** add event listener message.')
        navigator.serviceWorker.addEventListener('message', function(event) {
          const data = event.data;
          // Zeige einen Toast oder eine Benachrichtigung in der App an
          // showToast(data.title, data.message);
          this.message = data.message;
        })
      }, 3000)
    }
  },
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
