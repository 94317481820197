<template>
  <div>
    <event-navbar title="Product Index" />
    <v-runtime-template :template="header_content"></v-runtime-template>
    <div class="container-fluid">
      <product-index :event-short-name="$route.params.shortName" />
    </div>
    <v-runtime-template :template="footer_content"></v-runtime-template>
  </div>
</template>

<script>
import ListViewStorageMixin from './ListViewStorageMixin.js'
import VRuntimeTemplate from "v-runtime-template";
import EventNavbar from '@/components/EventNavbar.vue'
export default {
  name: 'ProductIndexView',
  mixins: [ListViewStorageMixin],
  components: { EventNavbar,  VRuntimeTemplate},
  created() {
    this.fetchBannerContent('product-index')
  },
}
</script>
