<template>
  <div>
    <event-navbar title="Exhibitor" />
    <div class="container-fluid">
      <exhibitor-detail :exhibitorId="$route.params.exhibitorId" exhibitor-type="exhibitor"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ExhibitorDetail from '@/components/exhibitor/exhibitor-detail.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'ExhibitorDetailView',
  components: {
    ExhibitorDetail, EventNavbar
  },
}
</script>
