import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import UserProfileView from '@/views/UserProfileView.vue'
import EventHomeView from '@/views/EventHomeView.vue'
import EventInfoView from '@/views/EventInfoView.vue'
import TalklistView from '@/views/TalklistView.vue'
import TalkDetailView from '@/views/TalkDetailView.vue'
import SpeakerListView from '@/views/SpeakerListView.vue'
import SpeakerDetailView from '@/views/SpeakerDetailView.vue'
import ProductIndexView from '@/views/ProductIndexView.vue'
import SponsorListView from '@/views/SponsorListView.vue'
import ExhibitorListView from '@/views/ExhibitorListView.vue'
import ExhibitorDetailView from '@/views/ExhibitorDetailView.vue'
import FavoriteListView from '@/views/FavoriteListView.vue'
import CommunityListView from '@/views/CommunityListView.vue'
import ContactListView from '@/views/ContactListView.vue'
import ContactDetailView from '@/views/ContactDetailView.vue'
import ChannelView from '@/views/ChannelView.vue'
import ContactChannelView from '@/views/ContactChannelView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/accounts/login',
    redirect: () => {
      return '/login'
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfileView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-profile/contacts',
    name: 'ContactListView',
    component: ContactListView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-profile/:favorite-favorites',
    name: 'FavoriteListView',
    component: FavoriteListView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/community',
    name: 'CommunityListView',
    component: CommunityListView,
    props: true,
  },
  {
    path: '/community/:contactId',
    name: 'Contact',
    component: ContactDetailView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/community/:contactId/:channelId',
    name: 'ContactChannelView',
    component: ContactChannelView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/channel-:channelName',
    name: 'Chat',
    component: ChannelView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/talks',
    name: 'Talks',
    component: TalklistView
  },
  {
    path: '/talks/:talkId',
    name: 'Talk Detail',
    component: TalkDetailView
  },
  {
    path: '/:shortName/talks',
    name: 'Event Talks',
    component: TalklistView
  },
  {
    path: '/:shortName/talks/:talkId',
    name: 'Event Talk Detail',
    component: TalkDetailView
  },

  {
    path: '/speakers',
    name: 'Speakers',
    component: SpeakerListView
  },
  {
    path: '/:shortName/speakers',
    name: 'Event Speakers',
    component: SpeakerListView
  },
  {
    path: '/speakers/:speakerId-:speakerName',
    name: 'Speaker Detail',
    component: SpeakerDetailView
  },
  {
    path: '/:shortName/speakers/:speakerId-:speakerName',
    name: 'Event Speaker Detail',
    component: SpeakerDetailView
  },

  {
    path: '/exhibitors',
    name: 'Exhibitors',
    component: ExhibitorListView
  },
  {
    path: '/:shortName/exhibitors',
    name: 'Event Exhibitors',
    component: ExhibitorListView
  },
  {
    path: '/exhibitors/:exhibitorId-:exhibitorName',
    name: 'Exhibitor Detail',
    component: ExhibitorDetailView
  },
  {
    path: '/:shortName/exhibitors/:exhibitorId-:exhibitorName',
    name: 'Event Exhibitor Detail',
    component: ExhibitorDetailView
  },

  {
    path: '/sponsors',
    name: 'Sponsors',
    component: SponsorListView
  },
  {
    path: '/:shortName/sponsors',
    name: 'Event Sponsors',
    component: SponsorListView
  },

  {
    path: '/product-index',
    name: 'ProductIndex',
    component: ProductIndexView
  },
  {
    path: '/:shortName/product-index',
    name: 'Event ProductIndex',
    component: ProductIndexView
  },

  {
    path: '/:shortName/:infopage',
    name: 'EventInfo',
    component: EventInfoView
  },
  {
    path: '/:shortName',
    name: 'EventHome',
    component: EventHomeView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

function getToken() {
  return localStorage.getItem('speaker_tool_token')
}

router.beforeEach((to, from, next) => {
  const token = getToken();

  router.lastRoute = from // memory the last Route

  // If the route requires authentication and token doesn't exist
  if (to.meta.requiresAuth && !token) {
    next('/login?next=' + to.fullPath) // Redirect to login route
  } else {
    next()
  }
})
