import Toast from "../lib/toast.vue"

export default {
  components: {Toast},

  data() {
    return {
      toastMessage: '',
    }
  },

  methods: {

    getFavorites: function() {
      let favs = this.$storage.getItem('favorite_session', [])
      if (this.eventShortName) {
        let favorite_session_keys_for_event = []
        for (const [code, session] of Object.entries(this.$storage.getItem('sessions', {}))) {
          if (session.event_short_name === this.eventShortName)
            favorite_session_keys_for_event.push(code)
        }
        this.numFavorites = favorite_session_keys_for_event.length
      } else
        this.numFavorites = favs.length
      return favs
    },

    onFavoriteChanged(content_code) {
      let sessions = this.$storage.getItem('sessions', {})
      let fav_event = sessions[content_code]

      if (fav_event) {
        for (const [code, event] of Object.entries(sessions)) {
          if (code === content_code) continue
          if (event.start_datetime === fav_event.start_datetime) {
            this.toastMessage = 'Du hast bereits ein Event mit gleicher Startzeit vorgemerkt!'
            break
          }
        }
      }
      this.getFavorites()
    },
  }
}