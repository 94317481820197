import Vue from 'vue'
import axios from "axios";

let config = {
  baseURL: process.env.VUE_APP_MATTERMOST_API_URL,
  responseType: 'json',
  headers: {
  },
  webSocketUrl: process.env.VUE_APP_MATTERMOST_API_URL.replace('https://', 'wss://') + '/websocket',
  guestToken: process.env.VUE_APP_MM_GUEST_TOKEN,
};

const _axios = axios.create(config);
Vue.prototype.$mattermost = _axios;

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    let token = localStorage.getItem('mm_token') || process.env.VUE_APP_MM_GUEST_TOKEN
    if (token)
      config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
