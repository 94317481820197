<script>

import ExhibitorListMixin from "./exhibitor-list-mixin.vue"

export default {
  name: 'SponsorList',
  mixins: [ExhibitorListMixin],

  data() {
    return {
      exhibitorType: 'sponsor',
    }
  }
}
</script>
