<script>
export default {
  data() {
    return {
      observed: this.$storage.observed
    };
  },

  computed: {
    numUnreadMessages() { return this.observed.numDirectUnreadMessages}
  },
};
</script>

<template>
  <div class="nav-item">
    <router-link to="/user-profile" class="nav-link nav-icon">
      <span v-if="numUnreadMessages" class="badge badge-pil badge-danger">
        <i class="fas fa-user"></i> {{ numUnreadMessages }}
      </span>
      <i v-else class="fas fa-user"></i>
    </router-link>
  </div>
</template>

<style scoped>
.badge {
  border-radius: 0.5rem;
}
</style>