<script>

export default {
  data() {
      return {
          email: '',
          password: '',
          error: ''
      };
  },
  methods: {
    fetchToken() {
      return this.$api.post('login/', {
          username: this.email,
          password: this.password
        }).then(response => {
          this.error = '';
          return response
        }).catch(error => {
          console.error('Error:', error);
          this.error = 'Problem bei der Anmeldung.';
        })
    },
    login() {
      let me = this
      this.fetchToken().then(response => {
        me.$storage.performLogin(response.data)
        me.$storage.askForPushNotificationPermission()

        if (me.$route.query.next) {
          me.$storage.fetchUser().then(() => {
            me.$router.push(me.$route.query.next)
          })
        } else {
          me.$router.push('/user-profile')
        }
      })
    },
    pwResetUrl() {
      return `${process.env.VUE_APP_ROOT_URL}accounts/password/reset/`
    },
    signupUrl() {
      return `${process.env.VUE_APP_ROOT_URL}accounts/signup/`
    },
  },
}
</script>

<template>
  <div>
  <nav class="nav navbar">
    <div class="nav-item">
      <router-link to="/" class="nav-link"><i class="fas fa-home float-right"></i></router-link>
    </div>
  </nav>
  <div class="container d-flex flex-column align-items-center">
    <img src="/media/logo.png" class="img-fluid my-5" alt="ZP Logo" style="max-width:6rem">
    <div class="card" style="width:18rem">
      <div class="card-body">
        <h2 class="card-title text-center">Login</h2>
        <div v-if="error" class="alert alert-danger">{{ error }}</div>
        <form>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" class="form-control" v-model="email">
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" class="form-control" v-model="password">
          </div>
          <div class="form-row justify-content-center">
            <button type="button" class="btn btn-primary" @click="login">Login</button>
          </div>
        </form>
      </div>
    </div>
      <br>
      <p><a :href="pwResetUrl()" target="_blank">Passwort vergessen?</a></p>
      <p><a :href="signupUrl()" target="_blank">Noch kein Login/Konto?</a></p>
  </div>
  </div>
</template>