<template>
  <div>
    <event-navbar :title="userDisplay()" :user-nav="false" />
    <div class="container">
      <ul class="list-group">
        <li v-for="(v, k) in favoritesByTitle" :key="k" @click.prevent="routeToFavorites(v)"
          class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
          {{ k }}
          <span class="badge badge-primary badge-pill">{{ favorites[favKey(v)]?.length || 0 }}</span>
        </li>

        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
         @click.prevent="contacts()">
          Kontakte
          <div>
          <span class="badge badge-danger badge-pill"><i class="far fa-envelope"></i> {{ numUnreadMessages }}</span>
          <span class="badge badge-primary badge-pill">{{ directChannels.length || 0 }}</span>
          </div>
        </li>

        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
         @click.prevent="logout()">
          Ausloggen
          <i class="fas fa-sign-out-alt"></i>
        </li>

      </ul>
      <p class="text-small text-muted text-right">App Version: {{ appVersion }}</p>
    </div>
    <div class="container">
      <app-rating product="App" />
    </div>
  </div>
</template>

<script>
import EventNavbar from '@/components/EventNavbar.vue'
import FavoriteTitlesMixin from '@/components/lib/favorite-titles-mixin.js'
import AppRating from '@/components/rating/app-rating.vue'

export default {
  name: 'UserProfileView',
  components: {EventNavbar, AppRating},
  mixins: [FavoriteTitlesMixin],
  data() {
    return {
      user: this.$storage.user,
      favorites: {},
      appVersion: process.env.APP_VERSION,
      initialUnreads: this.$storage.observed.numDirectUnreadMessages,
      observed: this.$storage.observed,
    }
  },

  computed: {
    directChannels() { return this.observed.directChannels },
    numUnreadMessages() { return this.observed.numDirectUnreadMessages },
  },

  created() {
    this.$storage.fetchUser().then((response) => {
      this.user = response.data
      this.favorites = this.$storage.getFavorites()
    }).catch(error_response => {
      if (error_response.response.status == 403) {
        console.log('redirecting ...')
        localStorage.removeItem('speaker_tool_token')
        this.$router.push('/login')
      }
    })
  },

  methods: {
    onUnreadMessages(num) {
      this.numUnreadMessages = num
    },
    userDisplay() {
      return `${this.user.first_name}  ${this.user.last_name}`.trim() || this.user.username
    },
    favKey(key) {
      return key == 'talk' ? 'favorite_session' : `favorite_${key}`
    },
    routeToFavorites(key) {
      let route = `/user-profile/${key}-favorites`
      this.$router.push(route)
    },
    logout() {
      this.$storage.performLogout()
      this.$router.push('/')
    },
    contacts() {
      this.$router.push('/user-profile/contacts')
    }
  },

}
</script>
