<template>
  <div>
    <event-navbar title="Community Contact" />
    <div class="container-fluid">
      <community-list></community-list>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CommunityList from '@/components/speaker/community-list.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'ContactListView',
  components: {
    CommunityList, EventNavbar
  },
}
</script>