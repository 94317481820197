<script>
// @ is an alias to /src
import ExhibitorList from '@/components/exhibitor/exhibitor-list.vue'
import SponsorList from '@/components/exhibitor/sponsor-list.vue'
import SpeakerList from '@/components/speaker/speaker-list.vue'
import TalkList from '@/components/speaker/talk-list.vue'
import EventNavbar from '@/components/EventNavbar.vue'
import FavoriteTitlesMixin from '@/components/lib/favorite-titles-mixin.js'

export default {
  name: 'FavoriteListView',
  mixins: [FavoriteTitlesMixin],
  components: {
    ExhibitorList, SponsorList, SpeakerList, TalkList, EventNavbar
  },
  props: {
      favorite: {type: String, required: true},
  },
  computed: {
    compName() {
      console.log(this.favoritesByKey)
      return this.favorite + '-list'
    }
  },
}
</script>

<template>
  <div>
    <event-navbar :title="favoritesByKey[favorite]" />
    <div class="container-fluid">
      <component :is="compName" />
    </div>
  </div>
</template>
