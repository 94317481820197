<template>
  <div>
    <nav class="nav navbar fixed-top bg-white">
      <div class="nav-item">
        <button class="btn btn-outline" @click.prevent="$router.back()">
          <i class="fas fa-chevron-left"></i>
        </button>
      </div>
      <div class="navbar-brand float-right"><b>{{ fullName }}</b></div>
    </nav>
    <div class="container-fluid mt-2 py-5">
      <channel-private :channelId="channelId" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ChannelPrivate from '@/components/channel/channel-private.vue'

export default {
  name: 'ContactListView',
  components: {
    ChannelPrivate
  },
  props: {
      channelId: {type: String, required: true},
      contactId: {type: String, required: true},
  },

  data() {
    return {
      contact: {},
    }
  },

  computed: {
    fullName() {
      return `${this.contact.first_name} ${this.contact.last_name}`.trim() || this.contact.username
    },
  },

  created() {
    this.fetchContact()
  },

  methods: {
    fetchContact() {
      let me = this
      this.$mattermost.post('/users/ids', [this.contactId]).then(function(response){
        me.contact = response.data[0]
      })
    }
  }
}
</script>
