<template>
  <div>
    <event-navbar title="Kontakte" />
    <div class="container-fluid">
      <contact-list></contact-list>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContactList from '@/components/speaker/contact-list.vue'
import EventNavbar from '@/components/EventNavbar.vue'

export default {
  name: 'ContactListView',
  components: {
    ContactList, EventNavbar
  },
}
</script>
