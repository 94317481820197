<template>
  <div>
    <nav class="nav navbar">
      <div class="d-flex flex-row">
        <div class="nav-item">
          <button @click="$router.back()" class="btn btn-outline"><i class="fas fa-chevron-left"></i></button>
        </div>
      </div>
      <div class="navbar-brand"><b>{{ $route.params.shortName.toUpperCase() }} - {{ info_title }}</b></div>
    </nav>
    <v-runtime-template :template="info_content"></v-runtime-template>
    <br>
  </div>
</template>

<script>
// @ is an alias to /src
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: 'EventInfoView',
  components: {
    VRuntimeTemplate
  },
  data() {
    return {
      info_content: '',
      info_title: '',
    }
  },

  computed: {
    storageKey() {
      return `zpcard-info-content-${this.$route.params.shortName}-${this.$route.params.infopage}`
    }
  },

  created() {
      let me = this
      if (navigator.onLine) {
        this.$api.get(`zpcard/${this.$route.params.shortName}/${this.$route.params.infopage}/`).then((response) => {
          me.info_content = response.data.html
          me.info_title = response.data.title
          localStorage.setItem(this.storageKey, me.info_content)
        })
      } else {
          me.info_content = localStorage.getItem(this.storageKey)
      }
  },

}
</script>
