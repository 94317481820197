<template>
  <div>
    <nav class="nav navbar fixed-top bg-white">
      <div class="nav-item">
        <button @click.prevent="$router.back()" class="btn btn-outline">
          <i class="fas fa-chevron-left"></i></button>
      </div>
      <div class="navbar-brand float-right"><b>{{ channel.display_name }}</b></div>
    </nav>
    <div v-if="channelId" class="container-fluid mt-2 py-5">
      <channel :channelId="channelId"></channel>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Channel from '@/components/channel/channel.vue'

export default {
  name: 'ChannelView',
  components: {
    Channel,
  },
  props: {
      channelName: {type: String, required: true},
  },
  data() {
    return {
      channelId: '',
      channel: {},
    }
  },
  computed: {
  },
  created() {
    if (navigator.onLine) this.fetchChannel()
  },
  methods: {
    fetchChannel() {
      let user_id = this.$storage.get('mm_user_id')
      let me = this
      this.$mattermost.get(`/users/${user_id}/channels`).then(response => {
        console.log(this.channelName)
        me.channel = response.data.filter(channel => channel.name == me.channelName)[0]
        me.channelId = me.channel.id
      })
    }
  }
}
</script>
