<template>
<div>
  <nav class="nav navbar">
    <div class="navbar-brand"><b>ZP Event App</b></div>
    <user-nav-item />
  </nav>
  <div class="home">
    <v-runtime-template :template="home_content" ></v-runtime-template>
  </div>
  <div v-if="deferredPrompt && displayPrompt" class="container-fluid semi-transparent fixed-bottom p-3">
    <button @click.prevent="displayPrompt=false"
     class="btn btn-outline float-right"><i class="fas fa-times-circle" /></button>
    <button @click="installApp" class="btn btn-secondary">
      Als App installieren
    </button>
  </div>
  <div v-if="displayUpdatePrompt" class="container-fluid semi-transparent fixed-bottom p-3">
    <button @click.prevent="displayUpdatePrompt=false"
     class="btn btn-outline float-right"><i class="fas fa-times-circle" /></button>
    <button @click="updateApp" class="btn btn-secondary">
      App Update verfügbar
    </button>
  </div>
  <br>
</div>
</template>

<script>
// @ is an alias to /src
import VRuntimeTemplate from "v-runtime-template";
import UserNavItem from "@/components/UserNavItem.vue"

export default {
  name: 'HomeView',
  components: {
    VRuntimeTemplate, UserNavItem
  },
  data() {
    return {
      home_content: '',
      deferredPrompt: null,
      appVersion: process.env.APP_VERSION,
      displayPrompt: true,
      displayUpdatePrompt: false
    }
  },
  created() {
    let me = this
    if (navigator.onLine) {
      this.$api.get('zpcard/').then((response) => {
        me.home_content = response.data.html
        localStorage.setItem('zpcard-home', me.home_content)
        if (response.data.app_version != this.appVersion)
          this.displayUpdatePrompt = true
      }).catch(error => {
        console.log('fetch error.', error)
        me.home_content = localStorage.getItem('zpcard-home')
      })
    } else {
        me.home_content = localStorage.getItem('zpcard-home')
    }
  },
   mounted() {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Verhindere, dass der Browser den Installationsdialog automatisch anzeigt
      event.preventDefault();
      // Speichere das Event-Objekt, um es später zu verwenden
      this.deferredPrompt = event;
      // Zeige deinen eigenen Installations-Button oder -Link an
      this.showInstallButton();
    })
    console.log('Version: ' + this.appVersion)
  },

    methods: {
    showInstallButton() {
      // Zeige einen Button oder Link zum Installieren der App an
      // Der Benutzer kann diesen Button verwenden, um die App zu installieren
    },
    updateApp() {
      window.location.reload()
    },
    installApp() {
      // Überprüfe, ob das deferredPrompt-Objekt verfügbar ist
      if (this.deferredPrompt) {
        // Fordere die Installation der App an
        this.deferredPrompt.prompt();
        // Warte auf das Ergebnis der Installation
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('Benutzer hat die Installation akzeptiert');
          } else {
            console.log('Benutzer hat die Installation abgelehnt');
          }
          // Setze das deferredPrompt-Objekt auf null, da es nur einmal verwendet werden kann
          this.deferredPrompt = null;
        });
      }
    },

    cacheImages(htmlContent) {
      // Create a temporary div element
      const tempDiv = document.createElement('div');

      // Set the innerHTML of the temporary div to your HTML content
      tempDiv.innerHTML = htmlContent;

      // Find all img elements within the temporary div
      const images = tempDiv.querySelectorAll('img');

      // Iterate over the images and memorize them on loading
      images.forEach(img => {
          caches.open('huhu').then( cache => {
            cache.add(img.src).then( () => {
            console.log(img.src, "cached ")
          });
        });
      })
    },

    loadImages(htmlContent) {
      // Create a temporary div element
      const tempDiv = document.createElement('div');

      // Set the innerHTML of the temporary div to your HTML content
      tempDiv.innerHTML = htmlContent;

      // Find all img elements within the temporary div
      const images = tempDiv.querySelectorAll('img');

      images.forEach(img => {
        caches.open('huhu').then(cache => {
          cache.match(img.src).then(response => {
            let blob = response.blob();
            img.src = URL.createObjectURL(blob);
            console.log('image created', img.src)
          })
        })
      })
    }
  }
}
</script>


<style scoped>
.semi-transparent {
  background-color: rgba(211, 211, 211, 0.5); /* Adjust the alpha value for transparency */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>