<script>

import ExhibitorListMixin from "./exhibitor-list-mixin.vue"

export default {
  name: 'ExhibitorList',
  mixins: [ExhibitorListMixin],

  data() {
    return {
      exhibitorType: 'exhibitor',
    }
  }
}
</script>
